<template>
  <div class="w-100 form-group">
    <label>
      Выбрать объект
    </label>
    <Multiselect
        :disabled="isEditingFraction"
        :options="houses"
        :value="flatData.house"
        noOptionsText="Список пуст"
        placeholder="Не выбрано"
        @clear="cleanProperties()"
        @select="getProperties($event)"
    />
  </div>
  <div class="my-3 form-group">
    <label>
      Тип недвижимости
    </label>
    <Multiselect
        :disabled="!flatData.house || isEditingFraction"
        :options="properties"
        :value="flatData.property"
        noOptionsText="Список пуст"
        placeholder="Не выбрано"
        @clear='getNumberProperty()'
        @select='getNumberProperty($event)'

    />
  </div>
  <div v-if="flatData.property === 'office'" class="my-3 form-group">
    <label>
      Номер квартиры/парковки/помещения
    </label>
    <Multiselect
        :disabled="!flatData.property || isEditingFraction"
        :options="numbersOffice"
        :value="flatData.numberProperty"
        noOptionsText="Список пуст"
        placeholder="Не выбрано"
        @blur="isTouchProperty = true"
        type="number"
        @select='selectOffice($event, $event)'
    />
  </div>
  <div v-else class="my-3 form-group">
    <label>
      Номер квартиры/парковки/помещения
    </label>
    <input
        :class="{ 'error': !isFound && isTouchProperty && foundNumber !== null}"
        :disabled="!flatData.property || isEditingFraction"
        :value="flatData.numberProperty"
        class="form-control"
        placeholder="Введите номер"
        type="text"
        @blur="isTouchProperty = true"
        @focus="isTouchProperty = true"
        @input="getUser($event.target.value)"
    />
  </div>
  <p v-if="flatData.property === 'apartment'" class="form error mb-0">
    {{ isTouchProperty && !isFound && !this.foundNumber && foundNumber !== null ? "Квартира с таким номером не найдена" : '' }}</p>
  <p v-if="flatData.property === 'parkinglot'" class="form error mb-0">
    {{ isTouchProperty && !isFound && !this.foundNumber && foundNumber !== null ? "Парковка с таким номером не найдена" : '' }}</p>
  <p  class="form error mb-0">
    {{isTouchProperty && !!repeatProperties && !isFound ? "Эта недвижимость уже добавлена" : '' }}</p>
  <add-fraction :fraction="flatData.fraction" :part="flatData.part" @update:part="$emit('update:flatData',
    Object.assign(flatData, {part: $event}))" @update:fraction="$emit('update:flatData',
    Object.assign(flatData, {fraction: $event}))">
  </add-fraction>
  <div v-if="!isUserView">
    <p>Для подтверждения собственности добавьте документы в формате jpg, png, pdf, doc или docx.</p>
    <file-upload-component :images="images"
                           :is-images="true"
                           @image="setImages($event)"
                           @files="setFiles($event)"
                           @rotate="setImagesRotate($event)"
                           @delete-image="setDeleteImages($event)"
                           @delete-files="setDeleteFiles($event)"
                           @change-popup="$emit($event)"
                           :added-files="addedFiles"
                           :added-images="addedImages"
                           :url-upload="urlUpload"
                           :is-popup = "true"
                           :is-editing="false"
                           :is-only-delete="true"
    >
    </file-upload-component>
  </div>

</template>

<script>
import Multiselect from '@vueform/multiselect'
import Housing from "../../../services/housing";
import AddFraction from "./AddFraction";
import fileUpload from "@/mixins/fileUpload";
import FileUploadComponent from "../files/FileUploadComponent";

export default {
  name: "AddFlatForm",
  components: {FileUploadComponent, AddFraction, Multiselect},
  emits: ['update:flatData', 'changePopup'],
  mixins: [fileUpload],
  props: {
    housesInfo: {
      type: Array,
    },
    myPetitionHouses: {
      type: Array,
    },
    flatData: {
      type: Object,
    },
    isEditingFraction: {
      type: Boolean,
      default: false
    },
    isUserView: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      houses: this.housesInfo.map((item) => {
        return {value: item.id, label: item.name}
      }),
      propertiesAll: this.housesInfo.map((item) => {
        return {key: item.id, prop: item.properties}
      }),
      properties: [],
      numbersProperties: [],
      numbersOffice: [],
      foundNumber: null,
      repeatProperties: null,
      isTouchProperty: false,
      controller: new AbortController(),
      urlUpload: 'properties/user_documents',
    }
  },
  async beforeMount() {
    if (this.isEditingFraction || this.flatData.house) {
      await this.getProperties(this.flatData.house);
      await this.getNumberProperty(this.flatData.property);
    }
  },
  methods: {
    getProperties(house) {
      this.properties = [];
      if (!this.isEditingFraction) {
        this.$emit('update:flatData', {house, part: this.flatData.part, fraction: this.flatData.fraction});
      }
      this.properties = Object.entries(this.propertiesAll.find((item) => item.key === house).prop).map((obj) => {
        return {value: obj[0], label: obj[1]}
      }).filter((item) => item.value !== "commonarea");
    },
    getNumberProperty(property) {
      this.numbersProperties = [];
      if (!this.isEditingFraction) {
        this.$emit('update:flatData', {house: this.flatData.house, property, part: this.flatData.part,  fraction: this.flatData.fraction});
      }
      this.isTouchNumber = false;
      if (property) {
        Housing.getListRooms(this.flatData.house, property).then((response) => {
          this.numbersProperties = response;
          if (this.flatData.property === "office") {
            this.numbersOffice = this.numbersProperties.map((item) => {
              return {value: item.id, label: item.apa_number}
            })
          }
        })
      }
    },
    addProp(numberProperty, isFound, number) {
        this.$emit('update:flatData', Object.assign(this.flatData, {numberProperty, isFound, number}));
    },
    selectOffice(numberProperty, number) {
        this.foundNumber = true;
        this.repeatProperties = null;
        this.isTouchNumber = false;
        if (this.myPetitionHouses?.length) {
            this.repeatProperties = this.myPetitionHouses.find(item => {
                return item.id === numberProperty;
            });
        }
        this.addProp(numberProperty, this.isFound, number);
    },
    getUser(numberProperty) {
      this.foundNumber = null;
      this.repeatProperties = null;
      this.isTouchNumber = false;
      if (numberProperty) {
        this.foundNumber = this.numbersProperties.find(item => item.apa_number === numberProperty);
        if (this.foundNumber && this.myPetitionHouses?.length) {
          this.repeatProperties = this.myPetitionHouses.find(item => item.id === this.foundNumber?.id);
        }
      }
      this.addProp(numberProperty, this.isFound, this.foundNumber?.id);
    },
    cleanProperties() {
      this.properties = [];
      this.$emit('update:flatData', {});
    },
    setDeleteImages() {
      return;
    },
  },
  computed: {
    isFound: {
      get() {
        return !!this.foundNumber && !this.repeatProperties;
      }
    },
  },
}
</script>

<style scoped>

</style>
