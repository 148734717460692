import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store/store";
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import CKEditor from '@ckeditor/ckeditor5-vue';
import Error from "@/services/error";





const app = createApp(App).use(router).use(store).use(VCalendar, {}).use(CKEditor);
app.config.globalProperties.version_number = process.env.VUE_APP_VERSION_NUMBER;
app.mount('#app');
app.config.errorHandler = function (err, vm, info) {
    // обработка ошибок
    const log = {
        url: router.currentRoute._value.fullPath,
        phone: store.state.user.number,
        text: `Error: ${err.message}; Info: ${info}`
    };
    Error.addLogError(log).then();
}


