function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function numberWithoutZero(x) {
    return x.toString().replace('.00', " ");
}
function numberPhone(x) {
    x = String(x);
    return '+' + x[0] + ' (' + x.slice(1, 4) + ')' + ' ' + x.slice(4, 7) + '-' + x.slice(7, 9) + '-' + x.slice(9, x.length)
}


export  {
    numberWithSpaces,
    numberPhone,
    numberWithoutZero
}
