import store from "../store/store";

function isMedia(media) {
    return media === 'media';
}

function getMediaUrl(id) {
    return '/api/media/media_get' + (store.state.device.isMobile? '_mobile': '' ) +'?id=' + id
}
export {
    isMedia,
    getMediaUrl
}
