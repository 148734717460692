const petitions = {
    state: () => ({
        editingPetition: {},
        editingFile: '',
        editingFileId:'',
    }),
    mutations: {
        setPetition(state, petition) {
            state.editingFile = petition.Files[0]?.name;
            state.editingFileId = petition.Files[0]?.id;
            state.editingPetition.id = petition.id;
            state.editingPetition.organisation = petition.Organisation?.id;
            state.editingPetition.category = petition.category?.id;
            state.editingPetition.caption = petition.caption;
            state.editingPetition.datestart = petition.datestart;
            state.editingPetition.dateend = petition.dateend;
            state.editingPetition.description = petition.description;
            state.editingPetition.housings = petition.Housings.map( item => {return  item.Housing?.id});
            state.editingPetition.targets = petition.Targets.map( item => {return item.Organisation?.id});
            state.editingPetition.type = petition.type;
            if (petition.type) {
                state.editingPetition.sqm_percent = petition.sqm_percent;
            } else {
                state.editingPetition.required = petition.required;
            }
        },
    }
};

export default petitions;
