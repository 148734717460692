import axios from "axios";
import API from "@/services/api";
import Error from "@/services/error";
import store from "@/store/store";

export default class Notification {

    static getNotifications() {
            return axios.get(`${API.API}/common/admin/notification`).then(
            (response) => {
                store.commit('setConfirmationValue', [{'BrowsingUsers': response.data.data.status}])
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error);
                return null;
            })
    }

}
