import axios from "axios";
import API from "./api";
import Error from "./error";
import store from "@/store/store";
export default class Login {
    static authLogin(params) {
        params.csrfmiddlewaretoken = axios.defaults.headers.post['x-csrftoken'];
        params.csrf_token = axios.defaults.headers.post['x-csrftoken'];
        return axios.post(`${API.API}/common/auth/login`, params).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }
    static authRegister(params) {
        params.csrfmiddlewaretoken = axios.defaults.headers.post['x-csrftoken'];
        params.csrf_token = axios.defaults.headers.post['x-csrftoken'];
        return axios.post(`${API.API}/common/auth/register`, params).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getUserDetails() {
        return axios.get(`${API.API}/common/user/get`).then(
            (response) => {
                store.commit('setFullUserInformation', response.data.data);
               return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static authLogout() {
        return axios.post(`${API.API}/common/auth/logout`).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static loginWithPhone(isSave) {
        const params = {
            phone : store.state.user.number,
            phone_request_id: store.state.request.requestId,
            code: store.state.request.code,
            remain: isSave ? 1 : undefined
        }
            return axios.post(`${API.API}/common/auth/login_with_phone`,params).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static setPassword(params) {
        return axios.post(`${API.API}/common/auth/setpassword`,params).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }
}
