const savingFilters = {
    state: () => ({
        filter: {},
        filteringData: [],
        routeName: '',
    }),
    mutations: {
        setFilters(state, {currentFilters, data, routeName}) {
            state.filter = currentFilters;
            state.filteringData = data;
            state.routeName = routeName;
        },
    }
};

export default savingFilters;
