const nameMenu = {
    Goals: 'Сбор средств',
    PetitionViewing: 'Обращения',
    Home: 'Имущество',
    UserPayments: 'Платежи',
    UserProfile: 'Аккаунт',
    Gatherings: 'Управление сборами',
    PetitionViewingAdmin: 'Контроль обращений',
    Payments: 'Учет платежей',
    BrowsingUsers: 'Пользователи',
    Houses: 'Дома',
    UserSignatures: 'Подписанные обращения',
    TicketViewing: 'Заявки',
    TicketViewingAdmin: 'Работа с заявками',
    PetitionDetailsAdmin: 'Контроль обращений',
    TicketDetailsAdmin: 'Просмотр деталей заявки',
    TicketDetails: 'Просмотр деталей заявки',
    SurveyViewing: 'Опросы',
    SurveyViewingAdmin: 'Работа с опросами',
    SurveyDetailsAdmin: 'Работа с опросами'
}

export default nameMenu;
