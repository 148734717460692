import {isMedia} from "@/helpers/media";

const file = {
    state: () => ({
        type: '',
        typeFile: '',
        images: [],
        files: [],
    }),
    mutations: {
        setType(state, name) {
            // изменяем состояние
            state.type = name;
        },
        setTypeFile(state, name) {
            state.typeFile = name;
        },
        clearFiles(state) {
            state.files = [];
            state.images = [];
        },
        deleteImage(state, id) {
            state.images.splice(id, 1);
        },
        pushFile(state, {file, type}) {
            if (isMedia(type)) {
                state.images.push(file);
            } else {
                state.files.push(file);
            }
        }
    }
}

export default file;
