const house = {
    state: () => ({
        name: '',
        address: ''
    }),
    mutations: {
        changeHouse(state, house) {
            // изменяем состояние
            state.name = house.name;
            state.address = house.address;
        }
    }
}

export default  house
