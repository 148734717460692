<template>
    <Popup v-if="isOpenFlatPopup" @close-popup="closePopup" :is-hidden="isOpenPreviewImages"
           :header="`${ isHouseEditing ? 'Редактирование информации о недвижимости' : 'Добавление информации о недвижимости'}`">
        <AddFlat @add="add" @change-popup="isOpenPreviewImages= $event" :is-editing="isHouseEditing" :current-house="currentHouse"
                 :my-petition-houses="apartments" :is-user-view="isEditing"></AddFlat>
    </Popup>
  <div class="row">
    <div class="col-12 px-2 d-flex justify-content-end">
      <a v-if="apartments.length && !isEditing" class="btn btn-primary" @click="openPopup">
        <i class="fa-solid fa-plus small"></i> Добавить
      </a>
    </div>

  </div>
  <div v-if="apartments.length" class="mt-3">
    <div class="row">
      <div v-for="item in apartments" :key="item.housing.id + item.apa_number || item.apartment?.apa_number"
           class="col-12 col-md-6 col-lg-4 px-2 my-2">
        <div class="property-card">
          <img :src="require(`../../assets/images/${item.type || item.apartment?.type}.svg`)" alt="иконка"
               class="property-card__icon" style="position: absolute;top: 25px">
          <img class="property-card__action" src="../../assets/images/edit.svg"
               style="color: #067acd; position: absolute; right: 15px;top: 28px;"
               @click.stop="editHouse(item)">
          <div class="property-card__section">
              <div class="d-flex justify-content-between flex-column flex-xxl-row col-11">
                  <p class="property-card__title mr-1">
                      {{ getType(item) }}
                  </p>
                  <div class="detailed-status d-flex flex-column justify-content-center tooltip-gray tooltip-text align-self-start mb-2"
                       :aria-label="allStatus[item.status.id].comment">
                      <p :class="['bage mb-0 overflow-text-1 line-height', allStatus[item.status.id].class]">
                          {{ allStatus[item.status.id].desc }}</p>
                  </div>
              </div>
              <div>
              <p class="tooltip-content">№ {{ item.apa_number || item.apartment.apa_number }}</p>
                  <div v-if="item.status.id === 506 && item.status.comment">
                      <p class="pointer info text-decoration-underline color-primary"
                         @click="isShowComment = !isShowComment">Комментарий
                          <i :class="{'fa-angle-down': !isShowComment, 'fa-angle-up': isShowComment }"
                             class="fa-solid fa-angle-down"></i>
                      </p>
                      <div v-if="isShowComment">
                          <p class="description ck">{{item.status.comment}}</p>
                      </div>
                  </div>
              </div>
          </div>

          <div class="property-card__section">
            <p class="property-card__section-heading">
              Адрес
            </p>
            <div :aria-label="item.housing.address + ' этаж ' + (item.floor || item.apartment?.floor)"
                 class="tooltip-gray tooltip-text">
              <p class="tooltip-content">{{ item.housing.address }}, этаж {{ item.floor || item.apartment?.floor }}</p>
            </div>
          </div>
          <div class="property-card__section">

            <p class="property-card__section-heading">
              Общая площадь
            </p>
            <div class="property-card__section-description">
              <p>
                {{ (item.sqm || item.apartment?.sqm) ?? 'Не указана' }} <span
                      v-if="item.sqm || item.apartment?.sqm">м<sup>2</sup></span>
              </p>
            </div>
          </div>

          <div class="property-card__section">
            <p class="property-card__section-heading">
              Доля
            </p>
            <div class="property-card__section-description">
              <p>
                {{ (item.fraction || item.apartment?.fraction) ?? 'Не указана' }} %
              </p>
            </div>
          </div>

          <div class="property-card__section">
            <p class="property-card__section-heading">
              Голосующая площадь
            </p>
            <div class="property-card__section-description">
              <p>
                <template v-if="(item.sqm || item.apartment?.sqm) && (item.fraction || item.apartment?.fraction)">
                  {{ (((item.sqm || item.apartment?.sqm) * (item.fraction || item.apartment?.fraction)) /
                  100).toFixed(2) }} м<sup>2</sup>
                </template>
                <template v-else>
                  Не указана
                </template>
              </p>
            </div>
          </div>
          <div class="property-card__section mb-0">
            <p class="property-card__section-heading">
              Кадастровый номер
            </p>
            <div class="property-card__section-description">
              <p>
                {{ (item.cadastral || item.apartment?.cadastral ) ?? 'Не указан' }}
              </p>
            </div>
          </div>
          <div>
          </div>
          <images-component :images="item.doc" :image-class="isEditing ? 'document-views' : ''"
          :is-icon="true" :to-close-popup="toClosePopup"
          >
              <div v-if="isEditing" class="d-flex flex-column input-admin-comment">
                  <div class="px-0 mb-3">
                      <label class="paragraph bold" for="comment">
                          Комментарий к отклонению документов
                      </label>
                      <textarea
                          v-model="adminComment"
                          id="comment"
                          type="text"
                          name="details"
                          class="form-control input-custom short-answer"
                          placeholder="Комментарий к отказу в подтверждении документов"
                      ></textarea>
                  </div>
                  <div class="d-flex justify-content-between">
                      <button
                          @click="setDocumentsStatus(506, item.apartment.id)"
                          type="submit" class="btn btn-danger-outline mt-4">
                          Отклонить
                      </button>
                      <button
                          @click="setDocumentsStatus(504, item.apartment.id)"
                          type="submit" class="btn btn-primary mt-4">
                          Подтвердить
                      </button>
                  </div>
              </div>
          </images-component>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showEmptyState && !apartments.length" class="mx-auto text-center">
    <EmptyState :is-editing="isEditing" default-text="К cожалению, у вас ещё нет добавленной недвижимости "
                @add-apartment="openPopup"></EmptyState>
  </div>


</template>

<script>

import {mapState} from "vuex";
import Popup from "../../components/common/popup/Popup";
import AddFlat from "../../components/common/home/AddFlat";
import User from "../../services/user";
import EmptyState from "../../components/common/EmptyState";
import ImagesComponent from "../../components/ticket/Images";
import Admin from "@/services/admin";
import apartmentStatuses from "@/helpers/apartmentStatuses";

export default {
  name: 'HomeFrm',
  components: {
    ImagesComponent,
    AddFlat,
    Popup,
    EmptyState
  },
  beforeMount() {
    this.getApartmentsInfo();
  },
  data() {
    return {
      apartments: [],
      isOpenFlatPopup: false,
      currentId: 0,
      showEmptyState: false,
      isHouseEditing: false,
      currentHouse: {},
      adminComment: '',
      isOpenPreviewImages: false,
      allStatus: apartmentStatuses,
      isShowComment: false,
      toClosePopup: 0,
    }
  },
  computed: {
    ...mapState(['user']),
    isEditing: {
      get() {
        return this.$route.meta.role === 'Editing';
      }
    },
  },

  methods: {
    async getApartmentsInfo() {
      if (this.isEditing) {
        await User.getViewedUserApartment(this.$route.params.id).then(data => {
          this.apartments = data;
        })
        if (!this.apartments.length) {
          this.showEmptyState = true;
        }
      } else {
        this.apartments = this.user.apartments
        if (this.apartments.length === 0) {
          this.showEmptyState = true;
          this.openPopup();
        }
      }
    },
    getType(item) {
      switch (item.type || item.apartment?.type) {
        case 'parkinglot' :
          return 'Машиноместо';
        case 'apartment':
          return 'Квартира';
        case 'office':
          return 'Нежилое помещение';
        default :
          return '';
      }
    },
    openPopup() {
      this.isHouseEditing = false;
      this.currentHouse = {};
      this.isEditing = false;
      this.isOpenFlatPopup = true;
    },
      async add() {
          if (this.isEditing) {
              await User.getViewedUserApartment(this.$route.params.id).then(data => {
                  this.apartments = data;
              });
          } else {
              await User.getUserApartment();
              this.apartments = this.user.apartments
          }
          this.closePopup();
      },
    closePopup() {
      this.isOpenFlatPopup = false;
    },
    editHouse(item) {
      this.isOpenFlatPopup = true;
      this.isHouseEditing = true;
      this.currentHouse = item
    },
     async setDocumentsStatus(status, id) {
          const data = {
              use_id: +this.$route.params.id,
              id: id,
              status: status,
              comment: this.adminComment
          }
          const response = await Admin.setUsersDocumentStatus(data);
          if (response.status === 'ok') {
              this.add();
              this.toClosePopup ++;
          }
      }
  },
  watch: {
    "$route.name": function () {
      this.getApartmentsInfo();
    },
    "apartments.length": function () {
      if (!this.apartments.length) {
        this.showEmptyState = true;
      } else {
        this.showEmptyState = false;
      }
    }
  },

}
</script>
<style lang="scss">

.input-admin-comment {
    width: 50%;
}
@media screen and (max-width: 1440px) {
    .input-admin-comment {
        width: 70%;
    }
}
@media screen and (max-width: 950px) {
    .input-admin-comment {
        width: 100%;
    }
}
//.tooltip-gray:hover::after {
//  left: initial;
//}
</style>
