const status = {
    state: () => ({
        name: '',
    }),
    mutations: {
        setStatusName(state, name) {
            state.name = name;
        },
    }
};

export default status;