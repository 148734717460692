<template>
    <div  :class="['p-relative',{ 'image-box d-flex justify-content-center': isEditing }]">
        <div v-if="isEditing && image">
            <img src="../../assets/images/icon-xmark.png" @click="deleteImage()" class="middle-icon pointer" id=""
                 style="position: absolute; top: 5px; right:10px; z-index: 1" alt="">
            <img v-if="image.type !== 'pdf' && !isOnlyDelete" src="../../assets/images/icon-rotate.png" @click="rotateImage(image.id)" class="middle-icon pointer" alt=""
                 style="position: absolute; top: 5px; left:3px;  z-index: 1">
        </div>
        <div v-if="image.type === 'pdf' || isIcon" class="d-flex  flex-column justify-content-center text-ellipsis p-2 p-relative
        pointer" @click="setImage()">
            <i class="fa-regular  content-header ml-auto mr-auto" :class="{'fa-file': image.type === 'pdf',
            'fa-image': image.type !== 'pdf'}"></i>
            <span class="text-ellipsis text-center text-width">{{ image.name }}</span>
        </div>
        <img v-else :src="image.url" :key="image.id" @click="setImage()" :style="styleEditing"
             :class="['image__application_big pointer', parentClass]"
             alt="image">
    </div>

</template>

<script>

export default {
    name: "ImageComponent",
    emits: ['set-image','rotate','delete'],
    props: {
        image: Object,
        parentClass: String,
        isEditing: {
            type: Boolean,
            default: false
        },
        isOnlyDelete: {
            type: Boolean,
            default: false
        },
        isIcon: {
            type: Boolean,
            default: false,
        }
    },

    data () {
        return {
            rotationAngle: 0,
            currentHeight: 0,
        }
    },

    computed: {
        styleEditing: {
            get() {
                return this.isEditing ? {
                    transform: 'rotate(' + this.rotationAngle + 'deg)',
                    width: ''+ 220 - this.currentHeight +'px'
                } : {};
            }
        },

    },

    methods: {
        setImage() {
            this.$emit('set-image')
        },

        rotateImage(id) {
            this.rotationAngle = (this.rotationAngle + 90) % 360;
            if (this.rotationAngle === 90 || this.rotationAngle === 270) {
                this.currentHeight = 110;
            } else {
                this.currentHeight = 0;
            }
            this.$emit('rotate', {angle:this.rotationAngle, id:id})
        },
        deleteImage() {
            this.$emit('delete')
        },
    }

}

</script>

<style scoped>

</style>
