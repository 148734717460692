const notification = {
    state: () => ({
        notificationsList: [],
    }),
    mutations: {
        setConfirmationValue(state, item) {
            state.notificationsList = item;
        },
    }
};

export default notification;