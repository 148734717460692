<template>
    <div class="col-12 d-flex flex-wrap px-0 mb-3">
        <div id="drop-area" class="form-control d-flex justify-content-center
                                                                         py-3 flex-wrap image-box"
             :class="{ opacity_medium: isClassOpacityImage }"
             @dragenter.stop.prevent @drop.stop.prevent="uploadImage($event.dataTransfer?.files)"
             @dragover.stop.prevent="isClassOpacityImage = true"
             @dragleave.stop.prevent="isClassOpacityImage = false">
            <img src="../../../assets/images/download.svg" class="img" alt="">
            <input type="file" class="d-none" :id="id" multiple
                   v-on:change="uploadImage($event.target.files)">
            <p class="col-12 mt-1 text-center bold_medium">
                <label class="color-primary bold_medium mr-1 pointer mb-0" :for="id">
                    Выбрать файлы
                </label>
                <template v-if="!device.isMobile">или перетащите в эту область</template>
            </p>
        </div>
        <images-component :images="currentImages"
                          @delete="deleteImage($event)"
                          @rotate="rotateImage($event)"
                          @change-popup = "$emit('changePopup', $event)"
                          :is-popup="isPopup"
                          :is-editing="true"
                          :caption="imageCaption"
                          :is-only-delete="isOnlyDelete"
        />
    </div>
    <div class="mt-3">
        <div class="d-flex">
            <div v-for="(file, index) in files" :key="file.name" class="d-flex flex-column  mr-2
                                image-box justify-content-center text-ellipsis p-2 p-relative">
                <img src="../../../assets/images/icon-xmark.png" @click="deleteFile(index)" class="middle-icon pointer"
                     id=""
                     style="position: absolute; top: 5px; right:10px; z-index: 1" alt="">
                <i class="fa-regular fa-file content-header ml-auto mr-auto"></i>
                <span class="text-ellipsis text-center">{{ file.name }}</span></div>
        </div>
    </div>
</template>

<script>
import FileUploader from "@/services/file";
import {isMedia} from "@/helpers/media";
import {mapState} from "vuex";
import ImagesComponent from "@/components/ticket/Images";
import Media from "@/services/media";
import Ticket from "@/services/ticket";

export default {
    name: "FileUploadComponent",
    components: {ImagesComponent},
    emits: ['image', 'files', 'delete-image', 'delete-files', 'rotate', 'changePopup'],
    props: {
        images: {
            type: Array,
            default() {
                return []
            }
        },
        files: {
            type: Array,
            default() {
                return []
            }
        },
        imageCaption: {
            type: String,
            default: ''
        },
        addedFiles: {
            type: Array,
            default() {
                return []
            }
        },
        addedImages: {
            type: Array,
            default() {
                return []
            }
        },
        urlUpload: {
            type: String,
            default: ''
        },
        isImages: {
            type: Boolean,
            default: false
        },
        isPopup: {
            type: Boolean,
            default: false
        },
        isOnlyDelete: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            isClassOpacityImage: false,
            currentFiles: this.files || [],
            currentImages: this.images || [],
            imagesRotate: [],
            id: this.getRandomId(),
        }
    },

    beforeMount() {
        window.addEventListener('beforeunload', this.beforeWindowUnload);
        this.$store.commit('setType', 'ticket');
        this.$store.commit('clearFiles');
    },

    beforeUnmount() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
        this.beforeWindowUnload();
    },

    computed: {
        ...mapState(['device']),
    },

    methods: {
        uploadImage(files) {
            this.isClassOpacityImage = false;
            FileUploader.uploadFile(files, this.urlUpload).then((response) => {
                response.forEach(file => {
                    if ( this.isImages || isMedia(file.type)) {
                        this.currentImages.push(file);
                        this.$emit('image', {images: this.currentImages, file});
                    } else {
                        this.currentFiles.push({name: file.name, id: file.id});
                        this.$emit('files', {files: this.currentFiles, file: file.id});
                    }
                })
            })
        },
        deleteImage(index) {
            this.$emit('delete-image', {image: this.currentImages[index], index: index});
            this.currentImages.splice(index, 1);
            this.$emit('image', {images: this.currentImages});
        },
        deleteFile(index) {
            this.$emit('delete-files', this.currentFiles[index].id);
            this.currentFiles.splice(index, 1);
            this.$emit('files', {files: this.currentFiles});
        },
        rotateImage(data) {
            const existingImageIndex = this.imagesRotate.findIndex(item => item.id === data.id);
            if (existingImageIndex !== -1) {
                if (data.angle === 0) {
                    this.imagesRotate.splice(existingImageIndex, 1);
                } else {
                    this.imagesRotate[existingImageIndex].degree = data.angle;
                }
            } else {
                this.imagesRotate.push({id: data.id, degree: data.angle});
            }
            this.$emit('rotate', this.imagesRotate);
        },
        getRandomId() {
            const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
            return randLetter + Date.now();
        },
        beforeWindowUnload() {
            if (this.addedImages.length && !this.urlUpload) {
                Media.setMediaDelete({id: this.addedImages.map((item) => item.id)});
            }
            if (this.addedFiles.length) {
                Ticket.delFileTicket(this.addedFiles);
            }
        },
    }
}
</script>

<style scoped>

</style>
