import axios from "axios";
import API from "./api";
import store from "../store/store";
import Error from "./error";
import router from "@/router";




export default class User {
    static getUserApartment(gat_id) {
        const params = {gat_id}
        return axios.get(`${API.API}/properties/user_apartments`, gat_id ? {params}: {}).then(
            (response) => {
                return response.data.data;
            }
        ).catch((error) => {
            Error.addError(error)
        }).catch( () => []).then((data) => {
            if (!gat_id) {
                store.commit('setApartments', data);
            }
            return data
        })
    }

    static getViewedUserApartment(use_id) {
        return axios.get(`${API.API}/properties/admin/user_apartments`, {
            params: {
                use_id
            }
        }).then(
            (response) => {
                return response.data.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static setUserApartmentPart(params) {
        return axios.post(`${API.API}/properties/apartment_part_set`, params).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static setApartmentPart(params) {
        return axios.post(`${API.API}/properties/admin/apartment_part_set`, params).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static setUserDocuments(params)  {
        return axios.post(`${API.API}/properties/update_user_documents`, params).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }
    static setUserApartments(params) {
        return axios.post(`${API.API}/properties/user_apartments`, params).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getUserGatherings(params) {
        return axios.get(`${API.API}/payments/user_gatherings_get`,params ? {params}: {}).then(
            (response) => {
                return response.data.data;
            }
        ).catch((error) => {
            Error.addError(error)
        }).catch( () => [])
    }

    static getUserDirectGatherings(params) {
        return axios.get(`${API.API}/payments/user_gatherings_get_direct`,params ? {params}: {}).then(
            (response) => {
                return response.data.data;
            }
        ).catch((error) => {
            Error.addError(error)
        }).catch( () => [])
    }

    static getUserGatheringsClosed() {
        return axios.get(`${API.API}/payments/user_gatherings_get_closed`).then(
            (response) => {
                return response.data.data;
            }
        ).catch((error) => {
            Error.addError(error)
        }).catch( () => [])
    }

    static setUserGatheringClosed(params) {
        return axios.post(`${API.API}/payments/user_gathering_status_closed`, params).then(
            (response) => response.data.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static setUserPayment(params) {
        return axios.post(`${API.API}/payments/user_payment_set`, params).then(
            (response) => response.data.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getUserPayment() {
        return axios.get(`${API.API}/payments/user_payment_get`).then(
            (response) => response.data.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static delUserApartment(id) {
        return axios.delete(`${API.API}/properties/user_apartments/${id}`).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getUsers(params, signal) {
        return axios.get(`${API.API}/payments/payment_users`, {
            params,
            signal
        }).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            return Error.addError(error)
        })
    }

    static setUserInfo(params) {
        return axios.post(`${API.API}/common/user/set`, params).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }
    static getNewSms(params) {
        return axios.post(`${API.API}/sms/message_sendnew`, params).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }
    static getCheckPhoneCode(params) {
        return axios.post(`${API.API}/sms/message_setphonecode`, params).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getCheckCode(code) {
        return axios.post(`${API.API}/sms/code`, {request_id: store.state.request.requestId,
            phone: store.state.user.number, code}).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }
    static setNewPassword(params) {
        return axios.post(`${API.API}/common/auth/setpassword`, params).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static  setMobileRequest(params) {
        params.source = {object: store.state.request.type, id: store.state.request.id, password: store.state.request.password };
        return axios.post(`${API.API}/sms/request`, params).then(
            (response) => {
                if (response.data.status === 'OK') {
                    store.commit('setRequestId', response.data.data.req_id);
                    if (response.data.data.req_status === 2) {
                        store.commit('setRequestId', response.data.data.req_id);
                        router.push({name: 'MobileIdWaiting'})
                        return;
                    }
                    if (response.data.data.req_status === 3) {
                        store.commit('setRequestId', response.data.data.req_id);
                        router.push({name: 'SmsCheck'});
                        return;
                    }
                }
                router.push({name: 'PhoneVerification'});
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }
    static getMobileResult(params) {
        return axios.get(`${API.API}/sms/result`, {
            params
        }).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static checkExistsAccount(params) {
        return axios.post(`${API.API}/common/user/exists`, params).then(
            (response) => response.data.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getViewedUserDetails(user_id) {
        return axios.get(`${API.API}/common/user/admin/get`, {
            params: {
                user_id
            }
        }).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static checkLoginWithPhone(params) {
        return axios.post(`${API.API}/common/auth/login_with_phone`, params).then(
            (response) => response.data.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static setUserProperty(property) {
        return axios.post(`${API.API}/common/user/property`, {property}).then(
            (response) => response.data.data
        ).catch((error) => {
            Error.addError(error)
        })
    }
}


