<template>

    <popup-msg v-if="isOpenPopup" @close-popup="isOpenPopup = false" :is-image="true" :image-class="imageClass" :header="caption"
               :additional-header="isGroup? 'ГРУППА ЗАЯВОК': 'ЗАЯВКА'" :is-popup="isPopup">
        <div :class="['h-90 d-flex align-items-center justify-content-center p-relative flex-column', imageClass]">
            <template v-if="imagesInfo">
                <div class="w-100 mb-2"> {{imagesInfo[currentImage].house}}, {{imagesInfo[currentImage].place}} </div>
            </template>
            <div @click="setImage(currentImage - 1)" class="p-2 bg-white p-absolute left-0 opacity_medium pointer" v-if="images.length > 1">
                <i class="fa-solid fa-chevron-left mr-auto bold-text-3" ></i>
            </div>
            <div class="p-absolute centered-text">
                <load-ring v-if="!isLoaded"></load-ring>
            </div>
            <div class="mt-auto mb-auto" :class="{'w-100': images[currentImage].type === 'pdf'}">
                <embed  v-if="images[currentImage].type === 'pdf'"
                        :src="images[currentImage].url"  class="w-100 h-100" :style="{'height' : device.height*0.6 + 'px'}"
                        @load="isLoaded = true"/>
                <img v-else class="img-slider" :src="images[currentImage].url" @load="isLoaded = true"
                     :style="{'max-height' : device.height*0.6 + 'px'}" alt="img">
                <p class="text-center mt-2">{{currentImage + 1}}/{{images.length}}</p>
            </div>
            <div @click="setImage(currentImage + 1)" class="p-2 bg-white p-absolute right-0 opacity_medium pointer" v-if="images.length > 1">
                <i class="fa-solid fa-chevron-right ml-auto bold-text-3" ></i>
            </div>
            <slot></slot>
        </div>
    </popup-msg>
    <div v-if="visibleImages.length" class="d-flex flex-wrap  px-0">
        <div v-for="(image, index) in visibleImages" :key="image" :class="{'col-6 px-0 pr-2' : isBigImage, 'collection-content': isCollection}">
                <image-component :image="image" :parent-class="parentClass"
                                  @set-image="setImage(index)" @rotate="this.$emit('rotate', $event)"
                                  @delete="this.$emit('delete', index)" :is-editing="isEditing"
                                 :is-only-delete="isOnlyDelete"
                                 :is-icon="isIcon"
                >
                </image-component>

        </div>
    </div>
</template>

<script>
    import PopupMsg from "../common/popup/Popup";
    import LoadRing from "../common/LoadRing";
    import imageComponent from "@/components/ticket/ImageComponent";
    import {mapState} from "vuex";
    export default {
        name: "ImagesComponent",
        components: {LoadRing, PopupMsg, imageComponent},
        emits: ['rotate','delete', 'changePopup'],
        props: {
            images: Array,
            isBigImage: {
                type: Boolean,
                default: false
            },
            isCollection: {
                type: Boolean,
                default: false
            },
            isGroup : {
                type: Boolean,
                default: false
            },
            caption: {
                type: String,
                default: ''
            },
            isEditing: {
                type: Boolean,
                default: false
            },
            imagesInfo: Array,
            imageClass: {
                type: String,
                default: ''
            },
            isPopup: {
                type: Boolean,
                default: false
            },
            isOnlyDelete: {
                type: Boolean,
                default: false
            },
            isIcon: {
                type: Boolean,
                default: false,
            },
            toClosePopup: {
                type: Number,
                default: 0,
            }
        },
        data () {
          return {
              currentImage: -1,
              isLoaded: false,
              isOpenPopup: false,
          }
        },
        methods: {
            setImage(index) {
                this.isOpenPopup = true;
                this.isLoaded = false;
                this.currentImage = (index + this.images.length) % this.images.length;
            },
        },
        computed: {
            ...mapState(['device']),
            visibleImages() {
                return this.isBigImage ? this.images.slice(0, 2) : this.isCollection ? this.images.slice(0, 1) : this.images;
            },
            parentClass() {
                if (this.isEditing) {
                    return 'image-item'
                } else if (this.isBigImage) {
                    return 'w-100 mini-img image__application_all'
                } else if (this.isCollection) {
                    return 'collection-img'
                } else {
                    return 'mt-1 mr-2'
                }
            },
        },
        watch: {
            isOpenPopup: function () {
                this.$emit('changePopup', this.isOpenPopup);
            },
            toClosePopup: function () {
                this.isOpenPopup = false;
            },
        }
    }
</script>

<style scoped>

.h-90 {
    height: 90%;
}

</style>
