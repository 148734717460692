const request = {
    state: () => ({
        id: '',
        type: 'PetitionSign',
        requestId: null,
        success: null,
        error: null,
        password: '',
        method: '',
        code: undefined,
        source: '',
        text: '',
        mobileIdStatus: '',
    }),
    mutations: {
        changeRequest(state, {id, type, password}) {
            state.id = id;
            state.type = type;
            state.password = password;
        },
        setParamsRequest(state, {source, text}) {
          state.source = source;
          state.text = text;
        },
        setRequestId(state, id) {
            state.requestId = id;
        },
        setSuccessRequest(state, success) {
            state.success = success;
        },
        setErrorRequest(state, error) {
            state.error = error;
        },
        setMethodCheck(state, method) {
            state.method = method;
        },
        setCode(state, code) {
            state.code = code;
        },
        setMobileIdStatus(state, status) {
            state.mobileIdStatus = status;
        },
    },
    getters: {
        isRequestId: state => {
            return !!state.requestId;
        },
    },

};

export default request;
