const editingGathering = {
    state: () => ({
        gathering: {},
    }),
    mutations: {
        setGathering(state, gathering) {
            state.gathering.id = gathering.id;
            state.gathering.desc = gathering.desc;
            state.gathering.details = gathering.details;
            state.gathering.value = +gathering.value;
            state.gathering.datestart = gathering.datestart;
            state.gathering.dateend = gathering.dateend;
            state.gathering.periodic = gathering.periodic;
            state.gathering.housing = Object.keys(gathering.Housings);
            state.gathering.periodoffset = gathering.periodoffset;
            state.gathering.periodcount = gathering.periodcount;
            state.gathering.periodstart = gathering.periodstart;
            state.gathering.periodend = gathering.periodend;
        },
    }
};

export default editingGathering;
