<template>

  <div class="lds-ring lds-ring_blue mx-auto d-flex align-items-center my-3">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>

</template>

<script>
export default {
  name: "LoadRing",

}
</script>

<style scoped>

</style>