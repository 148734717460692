import store from "@/store/store";

const user = {
    state: () => ({
        isAuthenticated: undefined,
        apartments: [],
        roles: [],
        number: '',
        UserInformation: {},
        isWithoutMiddleName: false,
        houseId: undefined
    }),
    mutations: {
        changeLogin(state, isAuthenticated) {
            // изменяем состояние
            state.isAuthenticated = isAuthenticated;
        },
        deleteUserInfo(state) {
                state.roles = [];
                state.apartments = [];
                state.UserInformation = {};
        },
        setApartments(state, data) {
            state.apartments = data;
        },
        deleteApartment(state, id) {
            const index = state.apartments.findIndex((item) => item.id === id);
            state.apartments.splice(index, 1);
        },
        setRoles(state, data) {
            state.roles = data;
        },
        setFullUserInformation(state, data) {
            state.UserInformation = data;
        },
        setNumberPhone(state, number) {
           state.number = number;
        },
        setIsWithoutMiddleName(state, boolean) {
            state.isWithoutMiddleName = boolean;
        },
        setUserProperty(state, property) {
            state.UserInformation.property = property;
        },
        setHouseId(state, id) {
            state.houseId = id;
        }
    },

    getters: {
        isAuthenticated: state => {
            return !!state.isAuthenticated
        },
        isAdminRole: state => {
            return state.roles.length > 0;
        },
        isUserInformationComplete: state => {
            return !!state.UserInformation && !!state.UserInformation.first_name && !!state.UserInformation.last_name &&
                (state.UserInformation.middle_name_empty_check || !!state.UserInformation.middle_name)
        },
        isUnconfirmedProperty(state) {
            return state.apartments.every(item => item.status.id === 504);
        }
    },

    actions: {
        checkAuthenticated(state, isAuthenticated) {
            state.commit('changeLogin', isAuthenticated);
            if (state.isAuthenticated === false || state.isAuthenticated === undefined) {
                state.commit('deleteUserInfo');
            }
        },
        checkUnconfirmedProperty({  getters }) {
            if (!getters.isUnconfirmedProperty) {
                store.commit('addSuccess', {text: 'Ваш голос принят, но будет учтен после подтверждения недвижимости'});
            }
        },

    }


}

export default  user
