const fileUpload = {
    props: {
        defaultImages: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            files: [],
            images: this.defaultImages,
            imagesRotate: [],
            deletedImages: [],
            deletedFiles: [],
            addedImages: [],
            addedFiles: [],
        }
    },
    emits: ['changeImages'],
    methods: {
        setImages({images, file}) {
            this.images = images;
            if (file) {
                this.addedImages.push(file);
            }
            this.$emit('changeImages', images);
        },
        setDeleteImages(images) {
            this.deletedImages.push(images.image);
            this.isEditing ? this.currentTicket.images.splice(images.index, 1) : this.$store.commit('deleteImage', images.index);
        },
        setFiles({files, file}) {
            this.files = files;
            if (file) {
                this.addedFiles.push(file);
            }
        },
        setDeleteFiles(file) {
           this.deletedFiles.push(file);
        },
        setImagesRotate(images) {
            this.imagesRotate = images;
        },
    },
}

export default fileUpload;
