<template>
    <div  class="my-3 form-group">
        <label>
            Доля
        </label>
        <input
                type="text"
                placeholder="1/3 или 25%"
                class="form-control"
                :value="part"
                @input="getFraction( $event.target.value)"
        />
        <p class="form  mt-1 paragraph_medium" style="height: 19px" :class="{ 'error': error }">
            <template v-if="fraction">учитываемая доля {{fraction}}%</template>
            <template v-if="error">{{error}}</template>
        </p>

    </div>
</template>

<script>
    import Housing from "../../../services/housing";

    export default {
        name: "AddFraction",
        props: {
            part: {
                type: String,
            },
            fraction: {
                type: Number
            }
        },
        emits: ['update:part', 'update:fraction'],
        data() {
            return {
                controller: new AbortController(),
                error: ''
            }
        },
        methods: {
            getFraction(part) {
                this.$emit('update:part', part)
                this.controller.abort();
                this.controller = new AbortController();
                Housing.getPropertyFraction({part}, this.controller.signal).then((response) => {
                    if (response?.status === 'canceled') return;
                    this.$emit('update:fraction', response?.fraction);
                    this.error = response?.text;
                })
            }
        }
    }
</script>

<style scoped>

</style>
