import axios from "axios";
import API from "./api";
import Error from "./error";
export default class Media {

    static setMediaDelete(params) {
        return axios.post(`${API.API}/media/media_del`, params).then(
            (response) =>  {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static setMediaRotate(params) {
        return axios.post(`${API.API}/media/media_rotate`, params).then(
            (response) =>  {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

}
