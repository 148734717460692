import axios from "axios";
import API from "./api";
import Error from "./error";
import store from "../store/store";

export default class FileUploader {
    static uploadFile(file, url) {
        const data = new FormData();
        if (file.length) {
            for (let i=0; i< file.length; i++) {
                data.append( 'upload' + i, file[i] );
            }
        }else {
            data.append( 'upload', file );
        }
        data.append('type', store.state.file.type);
        return axios.post(`${API.API}/${url ? url : 'media/media_upload'}`, data).then(
            (response) => {
                let isTicket = store.state.file.type === 'ticket';
                // store.commit('setTypeFile', response.data.data[0].type);
                response.data.data.forEach(item => {
                    if (item.type === "error") {
                        store.commit('addError', item.name);
                    } else  {
                        store.commit('pushFile', {file: item.id, type: item.type});
                    }
                });
                return isTicket? response.data.data.filter((item) => item.type !== "error"): {default: response.data.data[0].url};
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

}
