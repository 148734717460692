<template>
  <img src="../../assets/images/oops.png" class="mx-auto d-block" alt="">
  <h4 class="my-4">
    {{defaultText}}
  </h4>
  <button v-if="!isEditing" class="btn btn-primary d-inline-block" @click="addApartment()">
    Добавить недвижимость
  </button>
</template>

<script>

export default {
  name: "EmptyState",
  props:{
    isEditing: {
      type: Boolean,
      default: false,
    },
    text: String,
    defaultText: {
      type: String,
      default: 'Вы не можете просматривать данный раздел, так как у вас еще нет добавленной недвижимости'
    },
  },
  emits: ['add-apartment'],


  methods: {
    addApartment() {
      this.$emit('add-apartment');
    },
  }
}
</script>
