const device = {
    state: () => ({
        isMobile: false,
        width: window.innerWidth,
        height: window.innerHeight
    }),
    mutations: {
        setDevice(state, isMobile) {
            state.isMobile = isMobile;
        },
        setResizeScreen(state) {
            state.width = window.innerWidth
            state.height = window.innerHeight
        }
    },
};

export default device;
