import nameMenu from "./nameMenu";

const menu = {
    client: {
        main: [{text: nameMenu.Goals, name: 'Goals', src: 'Goals'},
            {hide: true, name: 'Register'},
            {hide: true, name: 'Login'},
            {hide: true, name: 'Payment'},
            {text: nameMenu.PetitionViewing, name: 'PetitionViewing',src: 'Petition'},
            {text: nameMenu.TicketViewing, name: 'TicketViewing',src: 'Ticket'},
            {text: nameMenu.SurveyViewing, name: 'SurveyViewing',src: 'Survey'},

        ],
        additionally: [
            {text: nameMenu.Home, name: 'Home', src: 'Houses'},
            {text: nameMenu.UserPayments, name: 'UserPayments', src: 'Payments'},
            {text: nameMenu.UserSignatures, name: 'UserSignatures', src: 'UserSignatures'},

            // {text: 'Профиль', name: 'UserPayments', src: 'Payments'},
        ],
    },
    admin: {
        main: [{text: nameMenu.Gatherings, name: 'Gatherings',  src: 'Gatherings'},
            {hide: true, name: 'House'},
            {text: nameMenu.PetitionViewingAdmin, name: 'PetitionViewingAdmin',src: 'Petition'},
            {text: nameMenu.TicketViewingAdmin, name: 'TicketViewingAdmin',src: 'Ticket'},
            {text: nameMenu.SurveyViewingAdmin, name: 'SurveyViewingAdmin',src: 'Survey'},
            {hide: true, name: 'GatheringDetails'}, {text: 'Учет платежей', name: 'Payments', src: 'Payments'},
            {text: nameMenu.BrowsingUsers, name: 'BrowsingUsers',src: 'BrowsingUsers'},
            {text: nameMenu.Houses, name: 'Houses', src: 'Houses'},
            {hide: true, name: 'GatheringConstructor'}
        ],
        additionally: [
            {text: nameMenu.Home, name: 'viewHome', src: 'Houses'},
            {text: nameMenu.UserPayments, name: 'viewUserPayments', src: 'Payments'},

            // {text: 'Профиль', name: 'UserPayments', src: 'Payments'},
        ],
    }
};


export default menu;
