export default class API {

    static API = '/api';
    // static API = '/polls/api';
    static  config = {
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
        }
    }
}

