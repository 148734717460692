<template>
    <div class="d-flex flex-column w-100">
        <h4 class="popup__heading">{{defaultText}} {{text}}?</h4>
        <div class="d-flex">
            <p class="btn btn-primary mr-2"
               @click="confirm"
            >Да</p>
            <p @click="close" class="btn btn-danger-outline"
            >Нет</p>
        </div>
        <p v-if="isError" class="error color-red">
            {{errorText}}
        </p>
    </div>
</template>
<script>
    export default {
        name: "ConfirmMsg",
        props:{
            text: String,
            defaultText: {
                type: String,
                default: 'Вы уверены, что хотите удалить'
            },
            isError: Boolean,
            errorText: String
        },
        emits: ['confirm','close'],
        methods: {
            confirm() {
                this.$emit('confirm');
            },
            close() {
                this.$emit('close');
            }
        }
    }
</script>

