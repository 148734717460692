const apartmentStatuses = {
    501: {
        desc: 'Проверка документов',
        comment: 'Ваша заявка на подтверждение недвижимости находится на рассмотрении. Дождитесь подтверждения',
        class: 'bg-table-process'
    },
    503: {
        desc: 'Необходимо подтверждение недвижимости',
        comment: 'Подтверждением права собственности служит актуальная выписка ЕГРН ' +
            '(срок действия документа должен составлять не более 30 дней), либо доверенность, заверенная нотариусом',
        class: 'bg-table-attention'
    },
    504: {
        desc: 'Недвижимость подтверждена',
        comment: 'Ваша недвижимость подтверждена',
        class: 'bg-table-ready'
    },
    506: {
        desc: 'Подтверждение недвижимости отклонено',
        comment: 'Подтверждение недвижимости отклонено',
        class: 'bg-table-error'
    },
    507: {
        desc: 'Проверка документов на недвижимость (редактирование)',
        comment: 'Проверка документов на недвижимость',
        class: 'bg-table'
    },
}

export default apartmentStatuses;
