<template>
  <Popup v-if="isOpenConfirm" @close-popup="closeConfirm" :parent-class-background="'border-radius'">
    <Confirm text="квартиру" @close="closeConfirm" @confirm="deleteHouse"></Confirm>
  </Popup>
  <div class="form-group w-100 mr-3" ref="form">
    <add-flat-form v-if="housesInfo.length" :houses-info="housesInfo" v-model:flat-data="house" :my-petition-houses="myPetitionHouses"
                   :is-editing-fraction="isEditing"
                   :is-user-view="isUserView"
                   :default-images="images"
                   @changeImages="images = $event"
    >
    </add-flat-form>
      <div class="d-flex justify-content-between">
        <button :disabled="!house.isFound || !house.fraction"
              @click="create"
              type="submit" class="btn btn-primary mt-4">
        Сохранить
      </button>
      <button v-if="isEditing"
              @click="openConfirm"
              type="submit" class="btn btn-danger-outline mt-4">
        Удалить
      </button>
    </div>

  </div>
</template>

<script>
import Housing from "../../../services/housing";
import User from "../../../services/user";
import AddFlatForm from "./AddFlatForm";
import Popup from "@/components/common/popup/Popup";
import Confirm from "@/components/common/popup/Confirm";


export default {
  name: "addFlat",
  components: { AddFlatForm, Popup, Confirm},
  emit: ['add'],
  props: {
    currentHouse: Object,
    isEditing: {
      type: Boolean,
      default: false
    },
      isUserView: {
          type: Boolean,
          default: false
      },
    myPetitionHouses: {
      type: Array,
    },
  },
  data() {
    return {
      number: '',
      housesInfo: [],
      currentHome: {},
      isOpenConfirm: false,
      images:  this.isEditing ? this.currentHouse.doc : [],
      house: this.isEditing ? {
        isFound: true,
        house: this.currentHouse.housing.id,
        property: this.currentHouse.apartment.type,
        numberProperty: this.currentHouse.apartment.type === 'office' ? this.currentHouse.apartment.id : this.currentHouse.apartment.apa_number,
        fraction: this.currentHouse.fraction,
        part: this.currentHouse.fraction.toString()
      } : {},
    }
  },
  emits: ['add','changePopup'],
  async beforeMount() {
    this.housesInfo = await Housing.getHouses();
  },
  methods: {
    create() {
            let promise;
            if (this.isUserView) {
                promise = User.setApartmentPart({
                    use_id: +this.$route.params.id,
                    apa_id: this.currentHouse.apartment.id,
                    part: this.house.part
                });
            } else {
                if (this.isEditing) {
                    promise = User.setUserApartmentPart({
                        apa_id: this.currentHouse.apartment.id,
                        part: this.house.part,
                    });
                    User.setUserDocuments({
                        id: this.currentHouse.apartment.id,
                        doc: [...this.images.map((item) => item.id)]
                    }).then()
                } else {
                    promise = User.setUserApartments([{
                        hou_id: this.house.house,
                        apa_id: this.house.number,
                        part: this.house.part,
                        doc: [...this.images.map((item) => item.id)]
                    }]);
                }
            }
            promise
                .then(() => this.$emit('add'))
    },
    deleteHouse() {
       User.delUserApartment(this.currentHouse.id).then(() => {
           this.$store.commit('deleteApartment', this.currentHouse.id);
           this.$emit('add');
      });
    },
    openConfirm() {
      this.isOpenConfirm = true;
    },
    closeConfirm() {
      this.isOpenConfirm = false;
    },
    changePopup(isOpen) {
        this.$emit('changePopup', isOpen);
        if (isOpen) {
            this.$refs.form.offsetParent.scrollIntoView();
        }
    },
    setDeleteImages() {
        return;
    },
  },
}
</script>

<style >

.border-radius {
    border-radius: 16px;
}

</style>
