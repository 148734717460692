import axios from "axios";
import API from "./api";
import Error from "./error";
export default class Admin {

    static setUsersDocumentStatus(params) {
        return axios.post(`${API.API}/properties/admin/user_documents`, params).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }
}
