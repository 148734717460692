const error = {
    state: () => ({
        errors: [],
        success: [],
    }),
    mutations: {
        addError(state, error) {
            //добавление ошибки
            state.errors.push(error);
            setTimeout(() => {
                state.errors.shift();
            }, 10000)
        },
        addSuccess(state, success) {
            // добавление успешного уведомления
            state.success.push(success);
            setTimeout(() => {
                state.success.shift();
            }, 10000)
        }
    }
}

export default  error
