import axios from "axios";
import API from "./api";
import Error from "./error";
export default class Headers {

    static API = 'api';
    static header;

    static getCsrfToken() {
        return axios.get(`${API.API}/common/csrf_token`).then(
            (response) => {
                // axios.defaults.headers.post['X-CSRFToken'] = response.data.token;
                axios.defaults.headers.post['x-csrftoken'] = response.data.token;
                axios.defaults.headers.put['x-csrftoken'] = response.data.token;
                axios.defaults.headers.delete['x-csrftoken'] = response.data.token;
                axios.defaults.headers.post['csrfmiddlewaretoken'] = response.data.token;
                axios.defaults.headers.post['csrf_token'] = response.data.token;
                // this.header = {'X-CSRFToken': '1VdZuEYbnG1DsOOWZkSbavSgtx4akLhUJYtGX7OLdo7tSBOb8qEtlH6x4bvGIYIa'};
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getComplexInfo() {
        return axios.get(`${API.API}/properties/complex_info`).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }



}
