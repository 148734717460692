import axios from "axios";
import API from "./api";
import Error from "./error";
export default class Ticket {

    static createTicket(params) {
        return axios.post(`${API.API}/ticket/user_ticket_${params.id ? 'slave_': ''}set`, params).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static setEditTicket(params) {
        return axios.post(`${API.API}/ticket/ticket_edit`, params).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static setUserEditTicket(params) {
        return axios.post(`${API.API}/ticket/user_ticket_edit`, params).then(
            (response) => {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getUserTicket(id) {
        return axios.get(`${API.API}/ticket/user_ticket_get`,  {
            params: {
                id
            }
        }).then(
            (response) => response.data.data).catch((error) => {
            Error.addError(error)
        })
    }

    static getMasterTicketInfo(id) {
        return axios.get(`${API.API}/ticket/user_ticket_master_get`,  {
            params: {
                id
            }
        }).then(
            (response) => response.data.data).catch((error) => {
            Error.addError(error)
        })
    }

    static getTicket(id) {
        return axios.get(`${API.API}/ticket/ticket_get`,  {
            params: {
                id
            }
        }).then(
            (response) => response.data.data).catch((error) => {
            Error.addError(error)
        })
    }

    static getMasterTickets() {
        return axios.get(`${API.API}/ticket/ticket_master_all`).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getAllTickets(params, signal) {
        return axios.get(`${API.API}/ticket/user_ticket_${params.master_id? 'slave_': ''}all`, {
            params,
            signal
        }).then(
            (response) => response.data
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getTicketJoin(params, isCollection) {
        return axios.post(`${API.API}/ticket/user_ticket_join${isCollection?'_master': ''}`, params).then(
            (response) =>  {
                return response.data;
            }
            ).catch((error) => {
            Error.addError(error)
        })
    }

    static getTicketJoinDelete(params) {
        return axios.post(`${API.API}/ticket/user_ticket_join_delete`, params).then(
            (response) =>  {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static setTicketMaster(params) {
        return axios.post(`${API.API}/ticket/ticket_master_set`, params).then(
            (response) =>  {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static setUserTicketMaster(params) {
        return axios.post(`${API.API}/ticket/user_ticket_master_set`, params).then(
            (response) =>  {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static addTicketMaster(params) {
        return axios.post(`${API.API}/ticket/ticket_master_add`, params).then(
            (response) =>  {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static addUserTicketMaster(params) {
        return axios.post(`${API.API}/ticket/user_ticket_master_add`, params).then(
            (response) =>  {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static delTicket(id) {
        return axios.post(`${API.API}/ticket/ticket_del`, {id}).then(
            (response) =>  {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }
    static delFileTicket(id) {
        return axios.post(`${API.API}/ticket/ticket_files_del`, {id}).then(
            (response) =>  {
                return response.data;
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }
}
